export const emergencyContacts = [
  {
    country: "Austria",
    police: "133",
    ambulance: "144",
    fire: "122",
  },
  {
    country: "Belgium",
    police: "101",
    ambulance: "100",
    fire: "100",
    emergencyLine: "112",
  },
  {
    country: "Bosnia-and-Herzegovina",
    police: "122",
    ambulance: "124",
    fire: "123",
  },
  {
    country: "Croatia",
    police: "192",
    ambulance: "194",
    fire: "193",
  },
  {
    country: "Czech-Republic",
    police: "158",
    ambulance: "150",
    fire: "155",
    emergencyLine: "112",
  },
  {
    country: "France",
    police: "17",
    ambulance: "18",
    fire: "15",
    emergencyLine: "112",
  },
  {
    country: "Germany",
    police: "110",
    ambulance: "112",
    fire: "112",
  },
  {
    country: "Hungary",
    police: "107",
    ambulance: "104",
    fire: "105",
    emergencyLine: "112",
  },
  {
    country: "Macedonia",
    police: "192",
    ambulance: "194",
    fire: "193",
  },
  {
    country: "Malta",
    police: "112",
    ambulance: "112",
    fire: "112",
    emergencyLine: "112",
  },
  {
    country: "Norway",
    police: "112",
    ambulance: "113",
    fire: "110",
  },
  {
    country: "Poland",
    police: "997",
    ambulance: "999",
    fire: "998",
    emergencyLine: "112",
  },
  {
    country: "Slovakia",
    police: "158",
    ambulance: "155",
    fire: "150",
    emergencyLine: "112",
  },
  {
    country: "Slovenia",
    police: "113",
    ambulance: "112",
    fire: "112",
  },
  {
    country: "Spain",
    police: "091",
    ambulance: "061",
    fire: "080",
    emergencyLine: "112",
  },
  {
    country: "Switzerland",
    police: "117",
    ambulance: "114",
    fire: "118",
  },
  {
    country: "Sweden",
    emergencyLine: "112",
    police: "112",
    ambulance: "112",
    fire: "112",
  },
  // {
  //   country: "Tunisia",
  //   police: "197",
  //   ambulance: "190",
  //   fire: "198",
  // },
  {
    country: "Turkiye",
    police: "155",
    ambulance: "112",
    fire: "null",
  },
];
