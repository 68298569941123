export const funfacts = [
  {
    country: "Austria",
    facts: [
      "There are no kangaroos in Austria! ;)",
      "Especially in the eastern part of Austria ‘Oida’ is a quite unique, traditional term that has plenty of meanings depending on the situation",
      "Legends have it that the colors of the Austrian flag date back to the 11th century. The crusader Leopold V removed his sword belt after battle leaving a white stripe on his blood soaked tunique.",
      "Vienna, the capital of Austria, is home to almost one-quarter of the entire population of the country.",
      "Despite its relatively small area, Austria shares borders with eight other countries: Czech Republic, Germany, Hungary, Italy, Liechtenstein, Slovakia, Slovenia, and Switzerland.",
      "One of the oldest amusement parks in the world, the Wurstelprater is located in Vienna, Austria.",
    ],
  },
  {
    country: "Belgium",
    facts: [],
  },
  {
    country: "Bosnia-and-Herzegovina",
    facts: [
      "Sarajevo hosted the 1984 Winter Olympics",
      "Bosnia and Herzegovina consist of two Entities, 3 languages and 3 presidents.",
      "A Bosnian can either be a Bosniak, Croat or Serb based on his or her religious affiliations as a Muslim, Catholic or Orthodox Christian respectively",
      "Established in 1995 during the Bosnian War, Sarajevo Film Festival has become the largest and most famous film festival in the Balkans and South-East Europe.",
      "Bosnia has the last remaining jungle in Europe at Perućica - some of the trees are 300 years old. The country's highest peak, Maglić, provides a stunning view at it",
      "Banja Luka is a city with over 1000 cafes and restaurants. The first coffee bar was opened in 1617.",
      "Mostar is Bosnia and Herzegovina’s most divided city. Bosnian Croatians live on the western side of the Neretva River, and Bosniaks reside on the eastern side. Both sides have their own national theater, post office, and other separate public services.",
    ],
  },
  {
    country: "Croatia",
    facts: [
      "According to Alfred Hitchcock, the seaside city of Zadar in Dalmatia holds the title of the best sunset in the world.",
      "The world’s first torpedo was constructed in Rijeka",
      "Red Lake near the city of Imotski, Croatia is the deepest sinkhole in Europe, 3th largest in the world.",
      "Rice chocolate was invented in Croatia in 1963 at the Zvečevo factory in the western Slavonian city of Požega.",
      "Croatian Ivan Vučetić invented dactylography - a science of using fingerprints for identification, used today mainly in forensics but in other fields as well.",
    ],
  },
  {
    country: "Czech-Republic",
    facts: [
      "Our country is number one in drinking alcohol and beer especially.",
      "Our favorite destination for vacation is Croatia.",
      "Our favorite dress code is sandals with socks in it.",
      "We are very adventures that is why there is a lot of Czech tourist dying in the mountains without proper gear.",
      "Every autumn we go out for mushroom hunting because we love it more than eating.",
    ],
  },
  {
    country: "France",
    facts: [
      "France is the biggest country in Europe, with 633 000 km², followed by Spain and Sweden.",
      "Never put the “Baguette” upside down in the table!! It will bring bad luck.",
      "We eat frog legs, snails, oyster, rabbit or foie gras, but not every day! Usually we eat it for Christmas or New Year, but not all together!",
      "France is the only European country to have pigeon in the army. There are 150 homing pigeon in the French army, “in case of major emergency”.",
      "More than half of the roundabouts in the world are in France… Very useful for traffic, they are also very well decorated",
      "The Statue of Liberty in New York had been built by the French nation and offered to the USA for the centenary of their independence.",
    ],
  },
  {
    country: "Germany",
    facts: [
      "Most stores are closed on Sunday, except for restaurants and gas stations.",
      "The paint for White House in Washington DC, USA is produced in Germany.",
      "The Walt Disney’s Cinderella Castle is modelled after Schloss Neuschwanstein in Germany.",
      "Breaking out of prison isn’t punishable, because it is seen as basic human wish to be free.",
      "65% of the highways in Germany (Autobahn) have no speed limit.",
      "University is free for everyone (even non-Germans).",
      "There are more than 2100 castles and 1500 different beers in Germany.",
    ],
  },
  {
    country: "Hungary",
    facts: [
      "Hungary or Ungarn or Hungría or Węgry: we actually call it Magyarország. “Magyar” is Hungarian and “ország” is country. Hungarian-country.",
      "Over a thousand years and counting: Hungary is one of the oldest countries in Europe. It was founded in A.D. 896, before France, Germany, or England.",
      "The “Hungarian Sea”, Lake Balaton is the second largest lake in Europe and the largest in Central Europe.",
      "Letters everywhere: the Hungarian alphabet has 44 letters. Our longest word, “eltöredezettségmentesítőtleníttethetetlenségtelenítőtlenkedhetnétek” consist of 67 letters.",
      "Hungary is the home to the world’s first official wine region, Tokaj Region where wine has been produced since the 5th century.",
      "Pálinka is considered the cure of all ails. As the saying goes: “Palinka in small amounts is a medicine, in large amounts a remedy”.",
      "Budapest has continental Europe’s oldest metro.",
    ],
  },
  {
    country: "Macedonia",
    facts: [
      "It's home to one of the world’s deepest underwater caves: Cave Vrelo (literally means Hot Cave) is an underwater cave with a so far discovered depth of 230 meters.",
      "Home to the Europe’s oldest lake, Ohrid (Unesco World Heritage since 1979). The lake Ohrid is not only one of the oldest in the world (2-3 million years), but also one of the clearest.",
      "The Cyrillic alphabet was invented here. The Cyrillic alphabet was invented in Ohrid and it was based on the Glagolitic script developed by the two brothers Cyril and Methodious.",
      "It was the first country in the world to have full access to wireless broadband in 2006.",
      "It is home to one of the biggest cross monuments standing atop a hill in the capital Skopje standing at 66 meters tall.",
      "It is one of the countries with the most mountains per square kilometer, with more than 34 mountains higher than 2000 meters.",
      "Mother Teresa was an Albanian national born and raised in Skopje.",
    ],
  },
  {
    country: "Malta",
    facts: [
      "Fun facts about the country: Many parts of Game of Thrones Season 1 were filmed around Malta. Parts of The Gladiator and World War Z were also shot in Malta, in Valletta.",
      "Malta has as many Churches as there are days in a year (about 365 churches) in such a tiny nation and the main religion is Roman Catholicism.",
      "Our language (Maltese) descends from Arabic and is written in a latin alphabet. It contains many Italian (Sicilian) English and even some French words in the language. This makes Maltese a fusion language.",
      "Our stone age temples are actually older than the Pyramids of Giza.",
      "We have a population of just about half a million locals but we have tourists visiting absolutely all year round. This makes Malta one of the most densely populated countries in the world.",
      "Valletta was the capital city of European Culture in 2018.",
    ],
  },
  {
    country: "Norway",
    facts: [
      "Norway introduced salmon sushi to Japan.",
      "It’s illegal to die in Longyearbyen, cause the permafrost is preventing the bodies from decomposing.",
      "We got a knighted penguin.",
      "Norway has won the most gold, silver and bronze medals in the winter olympics.",
      "Sognefjorden is the deepest fjord in the whole world, it’s 1 308 m deep.",
    ],
  },
  {
    country: "Poland",
    facts: [
      "It's the 9th Largest Country in Europe. It's even bigger than Italy and the UK.",
      "The Unofficial Traditional Polish Food is Zapiekanka",
      "Poland had the second constitution in the world, following the United States",
      "It is home to the world's biggest castle - Malbork",
      "It has 16 world heritage sites.",
    ],
  },
  {
    country: "Slovakia",
    facts: [
      "Geographical midpoint of Europe – near the village Kremnické bane, right next to the st. John Baptist church.",
      "Slovakia has more than 1300 mineral springs.",
      "The oldest European marathon is held every year in Košice on the first Sunday in October.",
      "Every day in our country has some names assigned. We call it nameday. It's even written in all our calendars!",
      "Slovakia was established in 1993 and it’s one of the newest countries in the world.",
      "The geyser of Herľany is a rarity in Europe as there are only eight geysers like this one in the whole world.",
      "You can buy Absinthe in any alcohol shop.",
    ],
  },
  {
    country: "Slovenia",
    facts: [
      "It lies at the heart of Europe where the Alps and the Mediterranean meet the Pannonian plains and the Karst. Forests cover almost 60 % of its surface and more than 12 % of Sovenia’s territory is covered in designated national, regional and natural parks.",
      "There are more than 13 000 caves, 10.000 kilometers of marked hiking trails and almost 27 000 km of rivers, streams and other waterways.",
      "Slovenia is a land mountains and hiking. The largest mountain in Slovenia is Mount Triglav (2864 m).",
      "Slovenia is famous for its diverse and excellent wine. It is home to the oldest grape vine in the world. It is believed to be more than 400 years old and is the oldest living specimen of a noble grape vine that still bears grapes.",
      "During the winter Slovenians like to ski. Skiing is an important part of Slovenian culture and has existed here for more than 300 years.",
      "Slovenia is a nation of beekeepers. There are more than 10.000 beekeepers in Slovenia.",
    ],
  },
  {
    country: "Spain",
    facts: [
      "The highest mountain in the country is Mount Teide (3718 meters). This mountain is situated in the Canary Islands and is an active volcano. As a consequence of its eruptions, the beaches of the island of Tenerife are of black sand instead of white.",
      "According to stats, Spain has the second-highest life expectancy of all the OECD countries at an impressive 83 years. Only the Japanese have a higher life expectancy.",
      "SPAIN holds the European record for being home to the most bars per inhabitant, according to a recent EU study in 2017.",
      "Spanish are a tolerant society. Same-sex marriage has been legal in Spain since 2005 (One of the first 4 countries. The first one was the Netherlands in 2001).",
      "You can easily spot Spaniard anywhere, because they are the ones that speak in the loudest voice. It is part of the Spanish character and it is something that contributes to create that special atmosphere.",
      "Spain’s Christmas lottery has the world’s biggest payout",
      "Nudity is legal in Spain. With 3,000 hours of sunshine a year, there are also few better places to top up your tan.",
      "In Spain, on the 31st of December at 00.00h we eat 12 grapes.",
      "Spain is one of the few countries in Europe where it’s legal to consume cannabis. Cultivation is also allowed as long as it’s for personal use.",
    ],
  },
  {
    country: "Sweden",
    facts: [
      "Sweden has the most islands in the world - around 270 000!",
      "We love pastries and many of our famous pastries have their own days in the calendar, and during these days everyone eats them for fika!",
      "We love fika! Fika is an everyday must at all Swedish companies and it is a coffee and snack break taken in the morning and the afternoon. You can also go out and grab a fika with a friend at a café.",
      "Swedish Lappland (County which includes Kiruna, for example) is considered one of the best places to see the northern lights in the winter months!",
      `⅔ of Sweden is covered by forest, which means there are endless hiking possibilities thanks to “allemansrätten”. Allemansrätten roughly translates to "everyman's right" and means that you have the freedom to roam.`,
    ],
  },
  {
    country: "Switzerland",
    facts: [
      "Switzerland is the only country that has a square flag.",
      "Switzerland has more than 1,500 lakes.",
      "Tim Berners Lee invented the World Wide Web in Switzerland in 1989.",
      "In case of a nuclear war, Switzerland has enough bunkers to housing 114% of their entire population.",
      "Albert Einstein invented the famous equation E=MC2 while he was in Switzerland.",
      "Charlie Chaplin spent the last 25 years of his life in Switzerland.",
    ],
  },
  // {
  //   country: "Tunisia",
  //   facts: [
  //     "The network of protected areas in Tunisia covers about 8% of the national territory. It is made up of 17 national park, plus other types of protected areas.",
  //     "Ichkeul National Park is a UNESCO World Heritage Site located in the north of Tunisia Ichkeul is the last remaining lake in a chain that once extended across North Africa.",
  //     "The Medina of Tunis is the Medina quarter of Tunis, capital of Tunisia. The Medina contains some 700 monuments, including palaces, mosques, mausoleums, madrasas and fountains dating from the Almohad and the Hafsid periods. It has been a UNESCO World Heritage Site since 1979.",
  //     "Tunisia has served as a popular location for some of Hollywood’s biggest movies, such as Jesus of Nazareth, The English Patient, Raiders of the Lost Ark and Star Wars!",
  //     "Fans of Star Wars have flocked to Tunisia to spot numerous filmmaking sites, including: Djerba (Episode IV), Tozeur, Lars Homestead Exterior (Luke’s home), Mos Espa, Tataouine...and so many more.",
  //   ],
  // },
  {
    country: "Turkiye",
    facts: [
      "İstanbul is said to be the center of the world considering its feature including both European and Asian sides.",
      "Santa Claus is from Turkey. Saint Nicholas was born far from the North Pole, in Patara. And he’s not the only saint with connections to Turkey — the Virgin Mary’s resting place could be near Ephesus, while Saint Paul was from Tarsus in the south.",
      "You might find chicken in your dessert. The signature Ottoman treat is tavuk göğsü, or chicken breast pudding.",
      "It has one of the world’s oldest and biggest malls.Istanbul‘s Grand Bazaar, or Kapalı Çarşı, dates to 1455 and was established shortly after the Ottoman conquest of Constantinople.",
      "Turkey gifted tulips to the world (you’re welcome, Netherlands). It’s uncertain where the first tulips were grown, but what is known is that the Ottomans popularized the flower and facilitated their introduction to Europe.",
    ],
  },
];
