export const committees = [
  {
    country: "Austria",
    lcs: ["Vienna", "Graz", "Innsbruck", "Leoben", "Linz"],
  },
  {
    country: "Belgium",
    lcs: ["Brussels", "Leuven", "Ghent"],
  },
  {
    country: "Bosnia-and-Herzegovina",
    lcs: ["Banja Luka", "Sarajevo", "Mostar"],
  },
  {
    country: "Croatia",
    lcs: ["Osijek", "Pula", "Rijeka", "Split", "Zagreb"],
  },
  {
    country: "Czech-Republic",
    lcs: [
      "Prague",
      "Brno",
      "Ostrava",
      "Pilsen",
      "Liberec",
      "Zlin",
    ],
  },
  {
    country: "France",
    lcs: ["Grenoble"],
  },
  {
    country: "Germany",
    lcs: [
      "Aachen",
      "Bayreuth",
      "Berlin",
      "Bochum",
      "Bonn",
      "Brandenburg",
      "Bremen",
      "Chemnitz",
      "Cottbus",
      "Darmstadt",
      "Detmold",
      "Dortmund",
      "Dresden",
      "Emden/Leer",
      "Erlangen",
      "Frankfurt",
      "Freiberg",
      "Freiburg",
      "Freising",
      "Gießen",
      "Göttingen",
      "Greifswald",
      "Halle",
      "Hamburg",
      "Hannover",
      "Harz",
      "Heide",
      "Heidelberg",
      "Illmenau",
      "Jena",
      "Kaiserslautern",
      "Karlsruhe",
      "Kiel",
      "Köln",
      "Konstanz",
      "Leipzig",
      "Lübeck",
      "Lüneburg",
      "Main",
      "Merseburg",
      "München",
      "Oldenburg",
      "Osnabrück",
      "Paderborn",
      "Potsdam",
      "Rostock",
      "Saarbrücken",
      "Stuttgart",
      "Weimar",
      "Wismar",
      "Zittau",
    ],
    page: "https://www.iaeste.de/the-local-committees/",
  },
  {
    country: "Hungary",
    lcs: ["Budapest", "Győr"],
  },
  {
    country: "Macedonia",
    lcs: ["Skopje", "Bitola", "Ohrid", "Stip"],
  },
  {
    country: "Malta",
    lcs: ["Msida"],
  },
  {
    country: "Norway",
    lcs: [
      "Bergen",
      "Grimstad",
      "Oslo",
      "Stavanger",
      "Tromsø",
      "Trondheim",
      "Ås",
    ],
  },
  {
    country: "Poland",
    lcs: [
      "Warszawa",
      "Szczecin",
      "Krakow",
      "Lodz",
      "Wroclaw",
      "Gliwice",
      "Rzeszow",
      "Gdansk",
      "Poznan",
    ],
  },
  {
    country: "Slovakia",
    lcs: ["Bratislava", "Žilina", "Zvolen", "Košice"],
  },
  {
    country: "Slovenia",
    lcs: ["Ljubljana", "Maribor"],
  },
  {
    country: "Spain",
    lcs: [
      "Madrid",
      "Bilbao",
      "Barcelona",
      "Zaragoza",
      "Lleida",
      "Valencia",
      "Sevilla",
      "Málaga",
      "Alicante",
      "Elche",
      "Aragón",
    ],
  },
  {
    country: "Switzerland",
    lcs: ["Zurich", "Bern", "Basel", "Lausanne"],
  },
  {
    country: "Sweden",
    lcs: ["Luleå", "Stockholm", "Linköping", "Gothenburg", "Lund"],
  },
  // {
  //   country: "Tunisia",
  //   lcs: ["Tunis"],
  // },
  {
    country: "Turkiye",
    lcs: ["İstanbul", "Ankara", "Kocaeli", "İzmir"],
  },
];
