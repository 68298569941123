export const metrics = [
    { id: 1, stat: "18", emphasis: "Member countries" },
    { id: 2, stat: "50+", emphasis: "Local Committees" },
    { id: 3, stat: "1K+", emphasis: "Internships" },
    { id: 4, stat: "1.5K+", emphasis: "Student Members" },
];

export const statInfo = [
    {
        id: 1,
        title: "IAESTE CER & CoRe",
        info: `The International Association for the Exchange of Students for
                    Technical Experience (IAESTE) provides young science and
                    engineering students with career-focused professional
                    internships abroad and employers with highly skilled, highly
                    motivated trainees. IAESTE Central European Region (IAESTE CER)
                    and Connect Region (CoRe) are two regional initiatives, focused
                    on the development of IAESTE in Europe.`
    },
    {
        id: 2,
        title: "Website's idea",
        info: `The International Association for the Exchange of
                    Students for Technical Experience (IAESTE) provides
                    young science and engineering students with
                    career-focused professional internships abroad and
                    employers with highly skilled, highly motivated
                    trainees. IAESTE Central European Region (CER) and
                    IAESTE Connect Region (CoRe) are two regional
                    initiatives focused on the development of IAESTE in
                    Europe. 
                    Ten European countries met in January
                    1948 at the initiative of the Imperial College Vacation
                    Work Committee, London led by Mr James Newby, hoping to
                    change the lives of young people through an
                    international exchange programme. Have they succeeded?
                    Let's find out!
                    The idea for this website came from our own years of
                    experience in providing abroad internships. You will
                    find here emergency contacts, public transport
                    directions, and even fun facts (in case you're not sure
                    how to start a conversation with a newly met intern
                    while travelling with IAESTE)!`
    }
]
